
    import { Component, Vue, Mixins } from "vue-property-decorator";
    import BeforeRouteEnterMixin from "@/mixins/beforeRouteEnterMixin";
    import ModalInformativePayload from "@/store/modules/modalInformative/models";
    import ReservationPayload from "@/store/modules/reservation/models";
    import RoutesEnum from "@/router/routesEnum";
    import ReservationTokenModel from "@/models/reservationTokenModel";
    import { ReservationModel } from "@/apiManager/reservation/reservationData";
    import { PaymentStatusEnum } from "@/apiManager/_common/enums";

    @Component
    export default class Redirect extends Mixins(BeforeRouteEnterMixin)
    {
        async mounted()
        {
            let token: ReservationTokenModel = new ReservationTokenModel();

            if (token.valid())
            {
                let reservCode: string = token.reservationCode;
                try
                {
                    await this.timeout(3000);
                    //@ts-ignore
                    let reservation: ReservationModel = this.$tStore.state.reservationState.detail;
                    let isPaymentDepositSelected: boolean = ReservationTokenModel.isPaymentDepositSelected();

                    if (reservation.listPayment.length > 0 && this.$route.name !== RoutesEnum.Payment)
                    {
                        let totalPayment = reservation.listPayment.reduce((total: number, payment) =>
                        {
                            if (payment.status === PaymentStatusEnum.Active)
                            {
                                let newTotal: number = total + payment.amount;
                                return Math.round(newTotal * 100) / 100;
                            }
                            else
                            {
                                return total;
                            }
                        }, 0);

                        let paymentMax: number = 0;

                        if (isPaymentDepositSelected && reservation.deposit > 0)
                        {
                            paymentMax = reservation.deposit;
                        }
                        else
                        {
                            paymentMax = reservation.total;
                        }

                        if (totalPayment >= paymentMax)
                        {
                            this.$tStore.commit(new ReservationPayload.Mutations.ClearIntervalTimer());

                            let payload = new ReservationPayload.Actions.CreateInvoice(this.g_Language, false);
                            try
                            {
                                await this.$tStore.dispatch(payload);
                            }
                            catch
                            {
                                this.$tStore.dispatch(new ReservationPayload.Actions.DeleteReservation(reservCode));

                                let payloadModal = new ModalInformativePayload.Mutations.DisplayModalInformative(
                                    this.i18n(this.getRezToursLanguageEnum.serverErrorTitle),
                                    this.i18n(this.getRezToursLanguageEnum.serverErrorMsg)
                                );
                                this.$tStore.commit(payloadModal);

                                return this.$router.replace(
                                    {
                                        name: RoutesEnum.Package
                                    }
                                );
                            }

                            ReservationTokenModel.delete();
                            this.$tStore.commit(new ReservationPayload.Mutations.SetReservationDetail(new ReservationModel()));
                            return this.$router.replace(
                                {
                                    name: RoutesEnum.Booking
                                }
                            );
                        }

                        return this.$router.replace({
                            name: RoutesEnum.Payment,
                            query: {
                                code: reservCode
                            }
                        });
                    }
                }
                catch(error)
                {
                }

                return this.$router.replace({
                    name: RoutesEnum.TravelBag,
                    query: {
                        code: reservCode
                    }
                });
            }
            else
            {
                this.$router.replace({
                    name: RoutesEnum.Package
                });
            }
        }

        timeout(ms: number)
        {
            return new Promise((resolve) =>
            {
                return setTimeout(resolve, ms);
            });
        }

        redirectAfterError()
        {
            return this.$router.replace(
                {
                    name: RoutesEnum.Package
                }
            );
        }

    }
